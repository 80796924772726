@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;1,500&display=swap');

:root {
  --clr1: #be1b1b;
  --clr2: #c73838;
  --clr3: #cf5454;
  --clr4: #d77171;
  --clr5: #df8d8d;
  --clr6: #e7aaaa;
  --clr7: #efc6c6;
}

.hero-wrapper {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.hero-wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.aboutus-text {
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.aboutus-text h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  color: var(--clr1);
  padding: 1rem 0;
}

.aboutus-text p {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: var(--clr4);
  font-weight: 400;
  padding: 5px;
  margin: 0;
  text-align: justify;
}

@media only screen and (max-width: 768px) {
  .aboutus-text {
    padding: 25px 0;
    margin-left: 20px;
    margin-right: 20px;
  }

  .aboutus-text h2 {
    font-size: 1.5rem;
    padding: 0.5rem 0;
  }

  .aboutus-text p {
    font-size: 0.9rem;
    padding: 3px;
  }
}



