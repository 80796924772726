@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;1,500&display=swap');

:root {
  --clr1: rgb(190, 27, 27);
  --clr2: rgb(236, 68, 90);
  --clr3: #fff;
  --clr4: #000;
  --clr5: lightgray;
  --clr6: yellow;
}

.footer {
  width: 100%;
  background-color: var(--clr1);
}

.footer-content {
  padding: 30px 20px; /* Adjust the padding to make the footer smaller */
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: wrap;
  gap: 20px;
}

@media (min-width: 768px) {
  .footer-content {
    justify-content: space-between;
    padding: 30px 0; /* Adjust the padding to make the footer smaller */
  }
}

.footer-logo {
    height: 150px; /* Adjust the height as needed */
    width: auto; /* Adjust the width as needed */
    margin-right: 10px; /* Adjust the margin as needed */

  }
  
  

.col {
  max-width: 300px;
}

.footer-title {
  margin-bottom: 20px;
  font-size: 20px;
  color: #fff;
}

.text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.c-item {
  display: flex;
  margin-bottom: 10px;
}

.c-item svg {
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 10px;
  margin-top: 4px;
  color: rgba(255, 255, 255, 0.971);
}

span.text {
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  color: #fff;
}

.bottom-bar {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.bottom-bar-content {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 10px;
}

@media (min-width: 768px) {
  .bottom-bar-content {
    padding: 0;
    height: 60px;
    max-width: 1200px;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
  }
}

.bottom-bar-content .text {
  font-size: 12px;
  color: rgb(255, 255, 255);
  text-align: center;
  flex: 1; /* Add this line to center the text vertically */
}


.foot-links {
    text-decoration: none;
    color: inherit;
  }
  
  .foot-links:hover {
    text-decoration: none;
    color: inherit;
  }