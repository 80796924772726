@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;1,500&display=swap');
:root {
  --clr1: #be1b1b;
  --clr2: #c73838;
  --clr3: #cf5454;
  --clr4: #d77171;
  --clr5: #df8d8d;
  --clr6: #e7aaaa;
  --clr7: #efc6c6;
}

.title {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 2.5rem;
  letter-spacing: .2rem;
}

.title span {
  color: var(--clr2);
  font-size: 4rem;
}

.title p {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--clr4);
}


.service {
    
  padding: 80px 0;
}

.service .title {
  text-align: center;
  margin-bottom: 50px;
}

.service .title h1 {
  font-size: 36px;
  font-weight: 600;
  color: #333;
  position: relative;
  z-index: 1;
}

.service .title h1 span {
  color: #f40000;
}

.services-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.services-col {
  width: 300px;
  margin: 20px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 30px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.services-col:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f40000;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

.services-col:hover:before {
  transform: scaleX(1);
}

.services-col:hover h2,
.services-col:hover p {
  color: #fff;
}  

.services-col i {
  font-size: 48px;
  color: #f40000;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.services-col:hover i {
  transform: rotate(360deg);
}

.services-col h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.services-col p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  position: relative;
  z-index: 1;
}

.services-col:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.services-col:hover p {
  color: #f40000;
}


@media (max-width:767px) {
  .service {
      margin-bottom: 30rem;
  }
}
