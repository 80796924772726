@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;1,500&display=swap');
/* be1b1b,c73838,cf5454,d77171,df8d8d,e7aaaa,efc6c6

  --clr1: #be1b1b
  --clr2: #c73838
  --clr3: #cf5454
  --clr4: #d77171
  --clr5: #df8d8d;
  --clr6: #e7aaaa;
  --clr7: #efc6c6


*/
:root {
  --clr1: #be1b1b;
  --clr2: #c73838;
  --clr3: #cf5454;
  --clr4: #d77171;
  --clr5: #df8d8d;
  --clr6: #e7aaaa;
  --clr7: #efc6c6;
}

.title {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.title h1 {
  font-size: 2.2rem;
  letter-spacing: 0.2rem;
  font-weight: 400;
  margin-top: 2rem;
}

.title span {
  color: var(--clr2);
  font-size: 3rem;
}

.aboutus-text {
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.aboutus-text h2 {
  font-size: 2rem;
  color: var(--clr1);
  padding: 1rem 0;
}

.aboutus-text h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: var(--clr4);
  padding-top: 20px;
  margin: 0;
  text-align: justify;
}

@media only screen and (max-width: 768px) {
  .aboutus-text {
    padding: 25px 0;
  }

  .aboutus-text h2 {
    font-size: 1.5rem;
    padding: 0.5rem 0;
  }

  .aboutus-text h3 {
    font-size: 0.8rem;
    padding: 15px 5px;
  }
}

@media only screen and (max-width: 576px) {
  .aboutus-text {
    padding: 10px 0;
  }

  .aboutus-text h2 {
    font-size: 1.2rem;
    padding: 0.3rem 0;
  }

  .aboutus-text h3 {
    font-size: 0.7rem;
    padding: 2px;
  }
}

.service {
  padding: 80px 0;
}

.service .title {
  text-align: center;
  margin-bottom: 50px;
}

.service .title h1 {
  font-size: 36px;
  font-weight: 600;
  color: #333;
  position: relative;
  z-index: 1;
}

.service .title h1 span {
  color: #f40000;
}

.services-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.services-col {
  width: 300px;
  margin: 20px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 30px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.services-col:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f40000;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

.services-col:hover:before {
  transform: scaleX(1);
}

.services-col:hover h2,
.services-col:hover p {
  color: #fff;
}  

.services-col i {
  font-size: 48px;
  color: #f40000;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.services-col:hover i {
  transform: rotate(360deg);
}

.services-col h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.services-col p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  position: relative;
  z-index: 1;
}

.services-col:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.services-col:hover p {
  color: #f40000;
}


.services-text h3 {
    color: #151010;
    font-size: 15px;
    text-align: center;
    font-weight: 500;
    padding: 5px;
}

.services-text h2{
    font-size: 2.5rem;
    color: var(--clr1);
    padding: 1rem 0;
}

@media (max-width:767px) {
  .service {
      margin-bottom: 30rem;
  }
}
