@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;1,500&display=swap');
:root {
    --clr1: rgb(190, 27, 27);
    --clr2: rgb(236, 68, 90);
    --clr3: #fff;
    --clr4: #000;
    --clr5: lightgray;
    --clr6: yellow;
}

.title {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 2.5rem;
  letter-spacing: .2rem;
}

.title span {
  color: var(--clr2);
  font-size: 4rem;
}

.title p {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--clr4);
}



.gallery-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px; /* add margin from left and right */
  }
  
  .gallery-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .gallery-column {
    flex: 1;
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .gallery-column img {
    width: 95%;
    max-height: 250px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .gallery-column img:hover {
    transform: scale(1.05);
  }
  

  @media screen and (max-width: 768px) {
    .gallery-row {
      justify-content: center;
    }
  
    .gallery-column {
      flex: 0 0 100%;
      margin-right: 0;
      margin-left: 0;
      display: flex;
      justify-content: center;
    }
  
    .gallery-column img {
      width: 90%;
      max-height: 250px;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    .gallery-column img:hover {
      transform: scale(1.05);
    }
  }
  
  