.wrapper {
  position: relative;
  width: 90%;
  margin: 0 auto;
  overflow-x: hidden;
}

.carousel {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  padding: 10px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.carousel::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.carousel > img {
  flex: 0 0 auto;
  width: calc(100% / 3);
  height: auto;
  margin-right: 10px;
  scroll-snap-align: center;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  cursor: pointer;
  z-index: 1;
  color: #fff;
}

#left {
  left: 10px;
}

#right {
  right: 10px;
}

@media screen and (max-width: 768px) {
  .carousel > img {
    width: calc(100% / 2);
  }
}

@media screen and (max-width: 480px) {
  .carousel > img {
    width: 100%;
    margin-right: 0;
  }
}
